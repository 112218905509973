import i18n from "i18next";
import parseHiddenMessage from "@common/parseHiddenMessage";
import { getDropdownDescription } from "@common/StringUtils";

import type { IMessage, IParticipant, IUserChatAction } from "@interfaces/Conversation";

// Action Related Messages Tools
const actionTypes = {
  password: 'password',
  address: 'address',
  otp: 'otp'
} as const;

type ActionType = keyof typeof actionTypes;

const lowerCaseIncludes = (target: string, substring: string): boolean => {
  if (typeof target !== 'string' || typeof substring !== 'string') return false;
  return target.toLowerCase().includes(substring.toLowerCase());
};

const containsValue = (target: string | undefined | null, actionType: ActionType) => {
  if (target && typeof target === 'string') {
    return lowerCaseIncludes(target, actionType);
  } else {
    return false;
  }
};

const containsPassword = (target) => containsValue(target, actionTypes.password);
const containsAddress = (target) => containsValue(target, actionTypes.address);
const containsOTP = (target) => containsValue(target, actionTypes.otp);

const isActionRelatedBy = (action: IUserChatAction, containFn: (target: any) => boolean) => {
  // get fields to check
  const description = action?.description;
  const fieldName = action?.fieldName;
  const fieldAttrDescription = action?.fieldAttribute?.description;

  // check if anything contains password
  return containFn(description) || containFn(fieldName) || containFn(fieldAttrDescription);
};

const isActionPasswordRelated = (action) => isActionRelatedBy(action, containsPassword);
const isActionAddressRelated = (action) => isActionRelatedBy(action, containsAddress);
const isActionOTPRelated = (action) => isActionRelatedBy(action, containsOTP);

export { isActionPasswordRelated, isActionAddressRelated, isActionOTPRelated };


export const REQ_STATES = {
  NOT_STARTED: "NOT_STARTED",
  FINISHED: "FINISHED",
  LOADING: "LOADING",
  ERROR: "ERROR"
} as const;

export type TReqState = keyof typeof REQ_STATES;
// General Messages Tools/Interfaces/Types
// This class can be used with any http request to track its state.
//  F.e. Right Before request, create instance of this class and call sent()
//  After req is complete and response is received, call finished()
//  IF catch(err) fired, call finished(err) within that catch block.
// use getState to check in which state is the request currently in
// possible states: NOT_STARTED, FINISHED, LOADING, ERROR
export class CReqState {
  isFinished: boolean;
  loading: boolean;
  error: Error | null;

  constructor() {
    this.isFinished = false;
    this.loading = false;
    this.error = null;
  }

  // Marks the current req as being sent BUT not finished
  // sets loading to true
  sent() {
    // if isFinished is true, it means that it is a prev req info
    // thus, reset state
    if(this.isFinished === true) {
      this.resetState()
    } 

    this.loading = true;
  }

  // Marks the current req as being finished.
  finished(error?: Error) {
    this.isFinished = true;
    this.loading = false;
    this.error = error ?? null;
  }

  // You can also add methods to the class as needed
  // For example, a method to reset the state
  resetState() {
    this.isFinished = false;
    this.loading = false;
    this.error = null;
  }

  getState(): TReqState {
     if(this.isFinished === true && this.loading === false) {
      return REQ_STATES.FINISHED;
    }
    else if(this.isFinished === false && this.loading === true) {
      return REQ_STATES.LOADING;
    }
    else if(this.isFinished === false && this.loading === false) {
      return REQ_STATES.NOT_STARTED;
    }
    else {
      return REQ_STATES.ERROR;
    }
  }
}

const sanitizeMessageText = (msg: string) => {
  if (msg.indexOf('<data/>') !== -1) {
    // Replace correction tag with acceptedData
    return msg.replace('<data/>', i18n.t('acceptedData')) + ')';
  }

  return msg;
}

export const constructFieldMessageText = (message: IMessage) => {
    const msg = sanitizeMessageText(message.message);
    
    return parseHiddenMessage(message) || getDropdownDescription(msg);
}

export const parseLocalDateTime = (message: IMessage) => {
  // Replace the datetime tag with the user's local with the time the message is created
  let result = message.message;
  if (message.message.indexOf('<datetime/>') !== -1) {
    // 1. Extract the datetime from the message
    const utcDate = new Date(message.createdAt);
    // 2. Convert to local 
    const localDateTime = utcDate.toLocaleString([], {  // Empty array for options, object for formatting
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short' // Include time zone name
    });
    result = message.message.replace('<datetime/>', localDateTime);
  }
  return result;
}

export const CheckIsAiInAudience = (intendedAudience: string[], aiOperatorIds: string[], messageUserId: string, messageSenderId: string, messageSenderType: string, messageOperatorView: boolean, userAudience: string[]): boolean => {
  // isAiInAudience doesn't include current operator or sender
  const isAiInAudience = Boolean((intendedAudience || []).find((userId) => aiOperatorIds.findIndex((operatorId) => operatorId === userId) !== -1 && userId !== messageUserId && userId !== messageSenderId));
  const isAiInSenderOrCurrentOperator = Boolean(aiOperatorIds.find((userId) => userId === messageUserId || userId === messageSenderId));
  if (messageOperatorView) {
    // Operator sending messages to or recieving messages from
    if (messageSenderType === 'Operator') {
      // Not a private message between two operators, get everyone involved
      if (intendedAudience?.length > 1) return false;
      // don't include the current operator or sender as an ai in 1:1 chats
      if (intendedAudience?.length === 1) return isAiInSenderOrCurrentOperator;
      // private messages between two operators who may or may not be ai
      return false;
    } else {
      // private messages between user and ai
      if (isAiInSenderOrCurrentOperator) return true;
      // private messages between user and non-ai operator
      return false;
    }
  } else {
    // User sending messages to or recieving messages from
    if (messageSenderType === 'Operator') {
      if (userAudience.length > 1) return true;
      // private messages between user and ai
      if (isAiInSenderOrCurrentOperator || isAiInAudience) return  true;
      // private messages between user and non-ai operator
      return false;
    } else {
      // private messages between two users and ai
      return false;
    }
  }
}

export const getAiOperatorIds = (participants: IParticipant[]): string[] => {
  return (participants || []).filter((p) => {
    // reduce optional chaining
    if (!p || !p.userData || !p.userData._id || p.userType === 'User') return false;
    // operator is AngelAi
    if (p.userData.accessLevel === 'Level 0') return true;
    // operator has AngelAi nickname
    const formattedNickname = (p.userData?.nickname || '').replace(/\s+/g, '').toLowerCase();
    if (formattedNickname === 'angelai') return true;
    return false;
  }).map((p) => p?.userData?._id).sort();
}

export const getUserAudience = (participants: IParticipant[], audience: string[]): IParticipant[] => {
  return (participants || []).filter((p, i, a) => {
    // reduce optional chaining
    if (!p || !p.userData || !p.userData._id || p.userType !== 'User') return false;
    // audience includes userId
    if (!(audience || []).find((userId) => p.userData._id === userId)) return false;
    // userId is unique
    if (a.findIndex((participant) => participant.userData._id === p.userData._id) !== i) return false;
    return true
  });
};

export const getOperatorAudience = (participants: IParticipant[], audience: string[]): IParticipant[] => {
  return (participants || []).filter((p, i, a) => {
    // reduce optional chaining
    if (!p || !p.userData || !p.userData._id || p.userType === 'User') return false;
    // audience includes operatorId
    if (!(audience || []).find((operatorId) => p.userData._id === operatorId)) return false;
    // operatorId is unique
    if (a.findIndex((participant) => participant.userData._id === p.userData._id) !== i) return false;
    return true;
  });
};

export const getAiAudience = (participants: IParticipant[], audience: string[]): IParticipant[] => {
  return (participants || []).filter((p, i, a) => {
    // reduce optional chaining
    if (!p || !p.userData || !p.userData._id || p.userType === 'User') return false;
    // audience includes operatorId
    if (!(audience || []).find((operatorId) => p.userData._id === operatorId)) return false;
    // operatorId is unique
    if (a.findIndex((participant) => participant.userData._id === p.userData._id) !== i) return false;
    // operator is AngelAi
    if (p.userData.accessLevel === 'Level 0') return true;
    // operator presents as AngelAi
    const formattedNickname = (p.userData?.nickname || '').replace(/\s+/g, '').toLowerCase();
    if (formattedNickname === 'angelai') return true;
    return false;
  });
};

export const getAudience = (message: IMessage, aiOperatorIds: string[], isAiInAudience: boolean): string[] => {
  const messageAudience = [message.userId, message.sender._id, ...(message.intendedAudience || [])].filter((v, i, a) => a.indexOf(v) === i).sort();
  const aiAudience = [...messageAudience, ...aiOperatorIds].filter((v, i, a) => a.indexOf(v) === i).sort();
  if (isAiInAudience) {
    return aiAudience;
  }
  return messageAudience;
}

/**
 * Combines the viewerId, messageSenderId, and messageIntendedAudience into a unique, alphabetically sorted array.
 *
 * @param viewerId - The ID of the viewer (expected to be a 24-character string).
 * @param messageSenderId - The ID of the message sender (expected to be a 24-character string).
 * @param messageIntendedAudience - An array of audience IDs to include.
 * @returns A unique, alphabetically sorted array of audience IDs.
 */
export const getAudienceArr = (
  viewerId: string,
  messageSenderId: string,
  messageSenderType: string,
  messageIntendedAudience: string[]
): string[] => {
  // Use a Set to store unique audience IDs
  const output: Set<string> = new Set();

  // Add all IDs from messageIntendedAudience if it's a valid array
  if (Array.isArray(messageIntendedAudience)) {
    for (const elm of messageIntendedAudience) {
      output.add(elm);
    }
  }

  // Add the messageSenderId if it's a valid 24-character string
  if (messageSenderId?.length === 24 && messageSenderType === "User") {
    output.add(messageSenderId);
  }

  // Add the viewerId if it's a valid 24-character string
  if (viewerId?.length === 24) {
    output.add(viewerId);
  }

  // Convert the Set to an array and sort the elements alphabetically
  return Array.from(output).sort();
};

/**
 * Compares two arrays of audience IDs to determine if they are equivalent, 
 * ignoring the order of elements.
 *
 * @param audienceA - The first audience array to compare.
 * @param audienceB - The second audience array to compare.
 * @returns `true` if both arrays contain the same elements (ignoring order), otherwise `false`.
 */
export const compareAudience = (
  audienceA: string[] = [],
  audienceB: string[] = []
): boolean => {
  // Ensure both inputs are arrays; return false if either is not an array
  if (Array.isArray(audienceA) === false || Array.isArray(audienceB) === false) {
    return false;
  }

  // If the arrays have different lengths, they cannot be equivalent
  if (audienceA.length !== audienceB.length) {
    return false;
  }

  // Sort both arrays to ensure element order does not affect comparison
  const sortedArr1 = [...audienceA].sort();
  const sortedArr2 = [...audienceB].sort();

  // Check if all elements in the sorted arrays match at the same index
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};
